import ApiService from "@/modules/pais-template/core/services/ApiService";
import JwtService from "@/modules/pais-template/core/services/JwtService";
import router from "@/modules/pais-template/router";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';
import ModuleConfig from '@/modules/Module';
export interface User {
    fullname: string;
    email: string;
    status: string;
    modules: {
        module: string;
        roles: string[]
    }[];
    image: string;
    id: string;
    account_type: string;
}

export interface UserAuthInfo {
  error: Record<string, unknown>;
  user: User;
  isAuthenticated: boolean;
}


@Module
export default class AppAuthModule extends VuexModule implements UserAuthInfo {
    error = {};
    user = {
        fullname: '',
        email: '',
        status: '',
        modules: [],
        image: '',
        id: '',
        account_type: ''
    } as User;
    token = '' as string;
    refresh_token = '' as string;
    isAuthenticated = false;
    isLocked = false as boolean;

    /**
     * Get api base url
     * @returns User
     */
    get apiBaseUrl(): string | undefined {
        return ApiService.getBaseURL();
    }

    /**
   * Verify user authentication
   * @returns boolean
   */
    get isUAuthenticated(): boolean {
        return this.isAuthenticated;
    }


    /**
     * Get api base url
     * @returns User
     */
    get userImageError(): string {
        return "/modules/pais-template/avatars/blank.png";
    }

    /**
     * Get api base url
     * @returns Image
     */
    get imageError(): string {
        return "/media/icons/duotone/Home/Picture.svg";
    }
    
    

    /**
     * Get current user object
     * @returns User
     */
    get appCurrentUser(): User {        
        return this.user;
    }

    get isAccountLocked(): boolean {
        return this.isLocked;
    }

    /**
     * Get authentification error
     * @returns array
     */
    get getError(): Record<string, unknown>  {
        return this.error;
    }

    @Mutation
    [Mutations.PURGE_AUTH](): void {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.error = {};
        JwtService.destroyToken();
    }

    @Mutation
    [Mutations.SET_ERROR](error: Record<string, unknown>): void {
        this.error = error;
    }

    @Mutation
    [Mutations.GO_DASHBOARD](){
        if (ModuleConfig.modules && ModuleConfig.modules.length == 1) {
            const moduleRoute = ModuleConfig.modules[0].route;
            router.replace(moduleRoute + '/dashboard').then(() => router.go(0));
        } else {
            router.replace('/dashboard').then(() => router.go(0));
        }
    }

    @Mutation
    [Mutations.SET_AUTH](data: { user:User, token, refresh_token, is_lock, account_type }): void {
        this.isAuthenticated = true;
        // Set account type
        data.user.account_type = data.account_type;
        this.user = data.user;
        this.isLocked = data.is_lock;
        this.token = data.token;
        this.refresh_token = data.refresh_token;
        this.error = {};
        
        JwtService.saveToken(this.token);
        JwtService.saveRefreshToken(this.refresh_token);
        ApiService.setHeader();
    }

    @Mutation
    [Mutations.SET_USER](user: User): void {        
        this.user = user;
    }

    @Action
    [Actions.OTP_DISABLED] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/api/users/disable-otp', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.OTP_ENABLED] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/api/users/enable-otp', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.TWO_STEP_SHOW_QR] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/api/users/two-factor-qr-code?password=${payload.password}`).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.TWO_STEP_SHOW_REC_CODES] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/api/users/two-factor-recovery-codes?password=${payload.password}`).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.CHANGE_PASSWORD] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/api/users/change-password', payload).then(({ data }) => {                
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }
    
    @Action
    [Actions.TWO_STEP_AUTH_ENABLE] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/two-factor-authentication', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.TWO_STEP_AUTH_DISABLED] (): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete('/api/users/two-factor-authentication').then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.NEW_PASSWORD] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/reset-password', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.RELOAD_CAPTCHA] (): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/reload-captcha', {}).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.PASSWORD_RESET] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/forgot-password', payload).then(({ data }) => {                
                resolve(data);
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.VERIFY_EMAIL_INVITATION] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log(payload);
            ApiService.get('/api/verify-invitation?' + payload.query + '&email=' + payload.email + '&token=' + payload.token).then(({ data }) => {
                if (data.auth_status) {
                    // Remove Login Token
                    JwtService.destroyLoginToken();
                    this.context.commit(Mutations.SET_AUTH, data);
                } else {
                    // Save Login token for two factor verification
                    JwtService.saveLoginToken(data);
                }
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.CURRENT_USER] (): Promise<void> {
        return new Promise<void>((resolve, reject) => {
             ApiService.get("/api/users/current-user").then(({ data }) => {
                resolve(data);
             }).catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response);
                reject();
            })
        });
    }

    @Action
    [Actions.VERIFY_AUTH](payload: { data, callback }): void {
        if (JwtService.getToken()) {
            // Set header
            ApiService.setHeader();
            // Set the apiservice to live url
            // I activated this "ismockapi" because of json-server
            // It needs to switch to live api everytime url changes.
            ApiService.isMockApi(false);
            ApiService.post("/api/refresh", payload.data).then(({ data }) => {
                if (data) {     
                    // Then set header from refresh token
                    ApiService.setHeader();
                    this.context.commit(Mutations.SET_AUTH, data);
                    const curRoute = router.currentRoute;
                    // prevent navigating in these routes
                    

                    // Check if session is lock
                    if (data && data.is_lock === true) {
                        const valRoute = curRoute.value;                        
                        // Redirect only when no in auth/lock page
                        if (valRoute.path !== '/auth/lock') {
                            const path = valRoute.fullPath;
                            router.replace('/auth/lock?redirect=' + path);
                        }                        
                    } else {
                        if (JwtService.preventRoutes.indexOf(curRoute.value.path) !== -1) { router.replace('/'); }
                    }
                    if (typeof payload.callback === 'function') {
                        payload.callback();
                    }
                }
            })
            .catch(({ response }) => {  
                if (response) { this.context.commit(Mutations.SET_ERROR, response.data); }                
                if (response && response.status === 401) {
                    this.context.commit(Mutations.PURGE_AUTH);
                    router.replace('/auth/sign-in');
                } else if (response && response.status === 500) {
                    // if refresh token is server internal error then we just reload the page for the mean time.
                    router.go(0);
                }
            });
        } else if (JwtService.getLoginToken()) {
            ApiService.setLoginHeader();
        } else {            
            this.context.commit(Mutations.PURGE_AUTH);
            router.replace('/auth/sign-in');
        }
    }

    @Action
    [Actions.LOGOUT](): Promise<void> {
        // Signout api and purge authentication
        console.log('signout');
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/logout', {}).then(({ data }) => {
                console.log(data);
                resolve(data)
            }).catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response);
                reject();
            })
        });
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.PURGE] (): void {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.UNLOCK] (payload: Record<string, unknown>): Promise<void> {        
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/disable-screenlock', payload).then(({ data }) => {
                resolve(data)
            }).catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response);
                reject();
            })
        });
    }



    @Action
    [Actions.LOCK] (payload: { data, redirectPath }): Promise<void> {        
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/enable-screenlock', payload.data).then(({ data }) => {
                if (data && data.type == 1) {
                    router.replace('/auth/lock?redirect=' + payload.redirectPath);
                }
                resolve();
            }).catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response);
                FormUtil.responseErrCatch(response);
                reject()
            })
        })
    }
    
    @Action
    [Actions.LOGIN](credentials: Record<string, unknown>): Promise<void> {        
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/api/login", credentials).then(({data}) => {
                if (data && data.type === 2) {
                    // Failed
                    FormUtil.responseErrCatch(data);
                } else {
                    if (data.auth_type === true) {
                    // Remove Login Token
                        JwtService.destroyLoginToken();
                        this.context.commit(Mutations.SET_AUTH, data);
                        this.context.commit(Mutations.GO_DASHBOARD);
                        //router.replace('/dashboard');
                    } else {
                        // Check if email already verified.
                        if(data.email_verified_at == null && data.email_verified_at !== undefined) {
                            // If not go to registered page 
                            // That page they can resend email confirmation
                            router.replace('/auth/registered/' + data.token + '/' + credentials.email + '?resend=true');
                        } else {
                            // Save Login token for two factor verification
                            JwtService.saveLoginToken(data);
                            router.replace('/auth/two-steps');
                        }
                    }
                }                
                resolve(data);
            }).catch(({response}) => {
                FormUtil.responseErrCatch(response);
                this.context.commit(Mutations.SET_ERROR, response.data)
                reject()
            })
        })
    }
}