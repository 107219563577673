
import MANTRIXModule from '@/modules/mantrix/router/index';
import ModuleConfigTypes from "@/modules/pais-template/core/config/ModuleConfigTypes";
const ModuleConfig: ModuleConfigTypes = {
    modules: [
        {
            asideHeading: 'Mantrix Information System',
            topHeading: 'Mantrix Information System',
            moduleName: 'mantrix',
            children : MANTRIXModule,
            route: '/mantrix-information-system',
            svgIcon: '/media/icons/duotone/Files/User-folder.svg'
        }
        // ,{
        //     asideHeading: 'Sample module',
        //     topHeading: 'Sample module only',
        //     moduleName: 'sample',
        //     route: '/s-a-m-p-l-e',
        //     fontIcon: 'las la-vial'
        // }
    ]
}
export default ModuleConfig;