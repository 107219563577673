// NOTE: PLEASE ADD PREFIX FOR IMPORT TO AVOID CONFLICTS.
// EX: [MODULE_PREFIX_NAME]CommonModule

import paisCommonModule from '@/modules/pais-template/store/modules/CommonModule';
import paisAppAuthModule from "@/modules/pais-template/store/modules/AuthModules";
import paisTwoStepAuthModule from "@/modules/pais-template/store/modules/TwoStepAuthModule";
import paisUserModule from "@/modules/pais-template/store/modules/UserModule";

// Administration
import paisAppAdminModule from "@/modules/pais-template/store/modules/admin/Module";
import paisAppAdminTeamsModule from "./modules/admin/TeamsModule";
import paisAppAdminUserModule from "./modules/admin/UserModule";
import paisAppAdminAnnouncement from "./modules/admin/AnnouncementModule";

//Components
import AppComponentSignatoryModule from "./components/SignatoryModule";
import AppComponentLogsModule from "./components/LogsModule";
import AppComponentsModule from "./components/Components";

const PaisStore = { 
    paisCommonModule,
    paisAppAuthModule,
    paisTwoStepAuthModule,
    paisUserModule,
    paisAppAdminModule,
    paisAppAdminTeamsModule,
    paisAppAdminUserModule,
    paisAppAdminAnnouncement,
    AppComponentSignatoryModule,
    AppComponentLogsModule,
    AppComponentsModule
}

export default PaisStore;