import { RouteRecordRaw } from "vue-router";

const pathPrefix = 'modules/mantrix';
const moduleBaseUrl = '/mantrix-information-system';
const routes: Array<RouteRecordRaw> = [
    { path: '', redirect: moduleBaseUrl + '/dashboard' },

    // START Dashboard Routes
    {
        path: 'dashboard',
        component: () => import('@/' + pathPrefix + '/views/pages/dashboard/index.vue'),
        // children: [
        //     { path: '', redirect: moduleBaseUrl + '/views/pages/dashboard/' },
        //     {
        //         path: 'dashboard', component: () => import('@/' + pathPrefix +"/views/pages/dashboard/index.vue"),
        //         children: [
        //             {
        //                 path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/dashboard/tabs/List.vue"),
        //             },
        //         ]
        //     },
        // ]
    },
    // END Dashboard Routes
    
    // TRANSACTION ROUTES
    {
        path: 'transaction',
        component: () => import('@/' + pathPrefix + '/views/pages/transaction/index.vue'),
        children: [
            { path: '', redirect: moduleBaseUrl + '/transaction/projects' },
            {
                path: 'projects', component: () => import('@/' + pathPrefix +"/views/pages/transaction/projects/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/transaction/projects/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/transaction/projects/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'dupa-cost', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-cost/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-cost/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/transaction/dupa-cost/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'dupa-material-cost', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-material-cost/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-material-cost/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/transaction/dupa-material-cost/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'dupa-labor-cost', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-labor-cost/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-labor-cost/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/transaction/dupa-labor-cost/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'dupa-equipment-cost', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-equipment-cost/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/transaction/dupa-equipment-cost/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/transaction/dupa-equipment-cost/tabs/Trashed.vue")
                    }
                ]
            },
        ]
    },
    // END

    // REFENCE ROUTES
    {
        path: 'references',
        component: () => import('@/' + pathPrefix + '/views/pages/references/index.vue'),
        children: [
            { path: '', redirect: moduleBaseUrl + '/references/materials' },
            {
                path: 'materials', component: () => import('@/' + pathPrefix +"/views/pages/references/materials/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/materials/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/materials/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'equipments', component: () => import('@/' + pathPrefix +"/views/pages/references/equipments/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/equipments/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/equipments/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'labor-types', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-types/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-types/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/labor-types/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'labor-methods', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-methods/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-methods/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/labor-methods/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'labor-names', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-names/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-names/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/labor-names/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'labor-hours', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-hours/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/labor-hours/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/labor-hours/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'unit', component: () => import('@/' + pathPrefix +"/views/pages/references/unit/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/unit/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/unit/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'durations', component: () => import('@/' + pathPrefix +"/views/pages/references/durations/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/durations/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/durations/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'designations', component: () => import('@/' + pathPrefix +"/views/pages/references/designations/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/designations/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/designations/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'scope', component: () => import('@/' + pathPrefix +"/views/pages/references/scope/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/scope/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/scope/tabs/Trashed.vue")
                    }
                ]
            },
            {
                path: 'sub-scope', component: () => import('@/' + pathPrefix +"/views/pages/references/sub-scope/index.vue"),
                children: [
                    {
                        path: 'list', component: () => import('@/' + pathPrefix +"/views/pages/references/sub-scope/tabs/List.vue"),
                    },
                    {
                        path: 'trashed', component: () => import('@/' + pathPrefix + "/views/pages/references/sub-scope/tabs/Trashed.vue")
                    }
                ]
            },
        ]
    },
    // END
    
]

export default routes;