import ApiService from "@/modules/pais-template/core/services/ApiService";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/modules/pais-template/store/enums/StoreEnums";
@Module
export default class CommonModulePAIS extends VuexModule {
    get paisModuleBreadcrumbs(): Array<string> {
        return ["Philippine Army Human Resource Management Information System"];
    }

    get siteName(): string | undefined {
        return process.env.VUE_APP_SITENAME;
    }

    get dateStarted(): string | undefined {
        return process.env.VUE_APP_PROJECT_STARTED;
    }

    get siteVersion(): string | undefined {
        return process.env.VUE_APP_VERSION;
    }

    get accountTypes(): Array<{ id: string; name: string; }> {
        return [
            { id: 'superadmin', name: 'Super Admin' },
            { id: 'developer', name: 'Developer' },
            { id: 'normal', name: 'Normal' }
        ];
    }

    @Action
    [Actions.CHECK_IMAGE_EXIST] (payload: { get }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(payload.get).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { reject(response) });
        });
    }
}