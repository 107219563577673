import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';
@Module
export default class AppAdminModule extends VuexModule {
    @Action
    [Actions.ADMIN_MODULE] (payload: { id, roleId }): Promise<void>  {
        return new Promise<void>((resolve, reject) => {
            let URL = '/api/modules/' + payload.id;
            if (payload.roleId) {
                URL+= '?role_id=' + payload.roleId;
            }
            ApiService.get(URL).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_MODULE_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/modules?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_MODULE_DELETED] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/modules/only-trashed?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    
    @Action
    [Actions.ADMIN_MODULE_ADDUPDATE] (payload) {
        let URL = "/api/modules";
        let URL_TYPE = "post";
        if (payload.id) {
            URL += '/' + payload.id;
            URL_TYPE = "put";
        }
        return new Promise<void>((resolve, reject) => {
            ApiService[URL_TYPE](URL, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_MODULE_GETDATA] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/modules/' + payload.moduleid).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_MODULE_ROLE] (payload: { roleId }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/roles/details', payload.roleId).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_MODULE_ROLE_USERS] (payload: { roleId, pagination, search }): Promise<void> {
        const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/roles/users', payload.roleId + "?" + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_MODULE_ROLES] (payload: { moduleId }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/modules/roles', payload.moduleId).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action // tobe deleted
    [Actions.ADMIN_MODULE_ROLES_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/modules/roles_without_module', payload.id).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action // tobe deleted
    [Actions.ADMIN_MODULE_SAVE_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('modules/assign/roles', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_ROLE_SAVE] (payload: { data }) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/roles', payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_ROLE_UPDATE] (payload: { id, data }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/api/roles/' + payload.id, payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_ROLE_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/roles?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

}
