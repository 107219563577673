
import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/modules/mantrix/store/enums/StoreEnums";
import { Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';


@Module
export default class MantrixDupaEquipmentCostsTransModule extends VuexModule {
    @Action
    [Actions.DUPA_EQUIPMENT_COSTS_TRANS_SAVE](payload): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            ApiService.post('/api/mantrix/trans/dupa-equipment-costs', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject();
            });
        });
    }

    @Action
    [Actions.DUPA_EQUIPMENT_COSTS_TRANS_UPDATE](payload): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            ApiService.put('/api/mantrix/trans/dupa-equipment-costs/' + payload.data.id, payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject();
            });
        });
    }

    @Action
    [Actions.DUPA_EQUIPMENT_COSTS_TRANS_TRASHED_LIST](payload: { pagination, search }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({ ...payload.pagination, ...payload.search });
            ApiService.get('/api/mantrix/trans/dupa-equipment-costs/only-trashed?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(); })
        });
    }

    @Action
    [Actions.DUPA_EQUIPMENT_COSTS_TRANS_LIST](payload: { pagination, search, showAll: boolean }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // let API_URL = "";
            const params = FormUtil.objectToString({ ...payload.pagination, ...payload.search });
            
            ApiService.get('/api/mantrix/trans/dupa-equipment-costs' + '?' + params).then(({ data }) => {
                payload.pagination,
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(); })
        });
    }

}