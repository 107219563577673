import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';

@Module
export default class AppAdminTeamsModule extends VuexModule {
    @Action
    [Actions.ADMIN_TEAM_SAVE_ADDED_MODULES] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('teams/assign/modules', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }  

    @Action
    [Actions.ADMIN_TEAM_MODULES_WITHOUT_TEAM] () {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('modules/show').then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_TEAM_LIST_SEARCH] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/teams/show-units?rowsPerPage=-1&keyword=' + payload.keyword).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_TEAM_ADD] (payload) {
        return new Promise<void> ((resolve, reject) => {
            ApiService.post('/api/teams', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_TEAM_DELETED] () {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/teams/only-trashed').then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_TEAM_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/teams?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_TEAM_USERS_VIEWLIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/teams/users-with-team/' + payload.teamid + '?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_TEAM_USERS_WITHOUT_TEAM] () {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/teams/users-without-team').then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_TEAM_SAVE_ADDED_USERS] (payload) {
        return new Promise<void>((resolve, reject) => {          
            ApiService.post('/api/teams/assign-users/' + payload.teamId, payload.data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });            
        })
    }

    @Action
    [Actions.ADMIN_TEAM_USER_UPDATE] (payload) {
        return new Promise<void>((resolve, reject) => {          
            ApiService.put('/api/teams/update/' + payload.user_id, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });            
        })
    }
}