enum Actions {
    // Administration ** Announcements **
    ADMIN_ANNOUNCEMENTS_LIST = "adminAnnouncementsList",
    ADMIN_ANNOUNCEMENTS_ADDUPDATE = "adminAnnouncementsAddUpdate",
    ADMIN_ANNOUNCEMENTS_DELETED = "adminAnnouncementsDeleted",
    // Administration ** Users **
    ADMIN_USER_ACCOUNTTYPE = "adminUserAccountType",
    ADMIN_USER_LIST = "adminUserList",
    ADMIN_PROFILE = "adminUserProfile",
    ADMIN_USER_INVITES = "adminUserInvites",
    ADMIN_USER_LISTDELETE = "adminUserListOfDeleted",
    ADMIN_USER_SET_ROLE_SAVE = "adminUserSetTeamSave",
    ADMIN_USER_SET_SUPERADMIN = "adminUserSetSuperadmin",
    ADMIN_USER_GET_SETTEAM = "adminUsersGetSetTeam",
    ADMIN_USER_INVITE = "adminUserInvite",
    // Administration ** Teams **
    ADMIN_TEAM_LIST_SEARCH = "adminTeamListSearch",
    ADMIN_TEAM_ADD = "adminTeamAdd",
    ADMIN_TEAM_LIST = "adminTeamGetList",
    ADMIN_TEAM_DELETED = "adminTeamGetDeleted",
    ADMIN_TEAM_USERS_VIEWLIST = "adminTeamUsersViewList",
    ADMIN_TEAM_USERS_WITHOUT_TEAM = "adminTeamUsersWithoutTeam",
    ADMIN_TEAM_SAVE_ADDED_USERS = "adminTeamSaveAddedUsers",
    ADMIN_TEAM_MODULES_VIEWLIST = "adminTeamModulesViewList",
    ADMIN_TEAM_MODULES_WITHOUT_TEAM = "adminTeamModulesWithoutTeam",
    ADMIN_TEAM_SAVE_ADDED_MODULES = "adminTeamSaveAddedModules",
    ADMIN_TEAM_USER_UPDATE = "adminTeamUserUpdate",
    // Administration ** Modules **    
    ADMIN_MODULE_LIST = "adminModuleList",
    ADMIN_MODULE = "adminModule",
    ADMIN_MODULE_DELETED = "adminModuleDeleted",
    ADMIN_MODULE_ADDUPDATE = "adminModuleAddUpdate",
    ADMIN_MODULE_GETDATA = "adminModuleGetData",
    ADMIN_MODULE_ROLES = "adminModuleGetRoles",
    ADMIN_MODULE_ROLE = "adminModuleGetRole",
    ADMIN_MODULE_ROLE_USERS = "adminModuleGetRoleUsers",
    ADMIN_MODULE_ROLES_LIST = "adminModuleGetListRoles", // tobe deleted
    ADMIN_MODULE_SAVE_LIST = 'adminModuleSaveList', // tobe deleted

    ADMIN_ROLE_UPDATE = 'adminRoleUpdate',
    ADMIN_ROLE_SAVE = 'adminRoleSave',
    ADMIN_ROLE_LIST = 'adminRoleList',

    // USER MODULE
    USER_UPDATE_DETAILS = "userUpdateDetails",
    USER_REGISTER = "userRegister",
    USER_REGISTERED_RESEND_EMAIL = "userRegisteredResendEmail",
    USER_REGISTERED_EMAIL_VERIFICATION = "userRegisteredEmailVerification",
    USER_UPDATE_PHOTO = "userUpdatePhoto",
    USER_GET_RIGHTS = "userGetRights",
    USER_RESEND_EMAIL_VERIFICATION = "userResendEmailVerification",

    // TWO STEP AUTHENTICATION
    TWO_STEP_SHOW_REC_CODES = "twoStepAuthShowRecoveryCodes",
    TWO_STEP_SHOW_QR = "twoStepAuthShowQR",
    TWO_STEP_AUTH_ENABLE = "twoStepAuthEnable",
    TWO_STEP_AUTH_DISABLED = "twoStepAuthDisabled",
    TWO_STEP_AUTH = "twoStepAuth",
    OTP_ENABLED = "otpEnabled",
    OTP_DISABLED = "otpDisabled",
    OTP_RESEND = "resendOTP",
    // AUTH
    CHANGE_PASSWORD = "changePassword",
    NEW_PASSWORD = "newPassword",
    PASSWORD_RESET = "passwordReset",
    LOGOUT = "appLogout",
    PURGE = "appPurgeCredential",
    LOGIN = "appLogin",
    VERIFY_AUTH = "appVerifyAuth",    
    RELOAD_CAPTCHA = "reloadCaptcha",
    LOCK = "appLock",
    UNLOCK = "appUnlock",
    SET_LOCK = "appSetLock",
    CURRENT_USER = "appGetCurrentUser", 
    VERIFY_EMAIL_INVITATION = "appVerifyEmailInv",

    // Template Actions
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",

    /**
     * Components
     */
    // Signatory
    APP_COMPONENT_SIGNATORY_PERSONNEL_SEARCH = "appComponentSignatoryPersonnelSearch",
    // SESSION LOGS
    APP_COMPONENT_LOGS_SESSIONS = "appComponentLogsSessions",
    APP_COMPONENT_ACTIVE_SESSIONS = "appComponentActiveSessions",
    TERMINATE_SESSION = "appComponentTerminateSession",
    CHECK_IMAGE_EXIST = "appCheckImageExists",
    // PAPIS
    APP_COMPONENT_PAPIS_PERSONNEL_SEARCH = "appComponentPAPISSearchPersonnels",
}

enum Mutations {
    GO_DASHBOARD = "appGoDashboard",
    // TWO STEP AUTHENTICATION

    // AUTH
    SET_AUTH = "appSetAuth",
    PURGE_AUTH = "appLogOut",
    SET_ERROR = "appSetError",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    SET_USER = "appSetUser",    

    //USER
    SET_PROFILE_VIEW_DATA = "appIsYourProfileView"
}
export { Actions, Mutations };