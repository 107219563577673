const ID_TOKEN_KEY = "id_token" as string;
const ID_RTOKEN_KEY = 'refresh_token' as string;
const ID_LOGIN = "id_login" as string;
const ID_AUTH = "id_auth" as string;
const ID_AUTH_MOBILE = "id_auth_mobile" as string;
const ID_EMAIL = "id_email" as string;

/**
 * @description get login token form localStorage
 */
export const getLoginToken = (): string | null => {
    return window.localStorage.getItem(ID_LOGIN);
}

/**
 * @description destroy login token form localStorage
 * 
 */
export const destroyLoginToken = () => {
    window.localStorage.removeItem(ID_LOGIN);
    window.localStorage.removeItem(ID_AUTH); // do not remove
    window.localStorage.removeItem(ID_AUTH_MOBILE); // do not remove
    window.localStorage.removeItem(ID_EMAIL); // do not remove
};

/**
 * @description save login token form localStorage
 */
export const saveLoginToken = data => {
    window.localStorage.setItem(ID_LOGIN, data.token);
    window.localStorage.setItem(ID_AUTH, data.auth_type); // do not remove
    window.localStorage.setItem(ID_AUTH_MOBILE, data.mobile_number); // do not remove
    window.localStorage.setItem(ID_EMAIL, data.email); // do not remove
};

/**
 * @description get login auth form localStorage
 */
export const getLoginAuth = () => {
    return window.localStorage.getItem(ID_AUTH);
};

/**
 * @description get login auth mobile number form localStorage
 */
export const getLoginAuthMobile = () => {
    return window.localStorage.getItem(ID_AUTH_MOBILE);
};

/**
 * @description get token from localStorage
 */
export const getToken = (): string | null => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description get token from localStorage
 */
export const getRToken = (): string | null => {
    return window.localStorage.getItem(ID_RTOKEN_KEY);
};


/**
 * @description get email from localStorage
 */
export const getEmail = (): string | null => {
    return window.localStorage.getItem(ID_EMAIL);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveRefreshToken = (token: string): void => {
    window.localStorage.setItem(ID_RTOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_RTOKEN_KEY);
};

export const preventRoutes = [
    'sign-in', 'sign-up', 'password-reset', 
    'two-steps', 'reset-password', 'registered', 
    'verify-email', 'reset-password-guest',
    'verify-guest', 'set-user-guest', '404'
];

export default { 
    preventRoutes,
    getToken, 
    saveToken, 
    destroyToken, 
    getLoginToken, 
    destroyLoginToken, 
    saveLoginToken, 
    getLoginAuth, 
    getLoginAuthMobile, 
    getEmail, 
    saveRefreshToken,
    getRToken
};
