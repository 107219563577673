import ApiService from "@/modules/pais-template/core/services/ApiService";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/modules/pais-template/store/enums/StoreEnums";
import { Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
@Module
export default class APPComponentsModule extends VuexModule {
    @Action
    [Actions.APP_COMPONENT_PAPIS_PERSONNEL_SEARCH](payload) {
        return new Promise<void>((resolve, reject) =>{
            ApiService.query('api/v1/mpis/personnel-search', { params: {
                ...payload.pagination,
                ...payload.search,
            }}).then( ({ data }) => {
                resolve(data)
            }).catch(({resp}) => {
                this.context.commit(Mutations.SET_ERROR, resp);
                reject();
            })
        });
    }
}