import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/modules/pais-template/store/enums/StoreEnums";
import ModuleConfig from '@/modules/Module';
import JwtService from "@/modules/pais-template/core/services/JwtService";
const pathPrefix = 'modules/pais-template/views/pages';
const routes: Array<RouteRecordRaw> = [
    {
        path: "/", redirect: "/dashboard", component: () => import("@/modules/pais-template/layouts/main-layout/MainLayout.vue"),
        children: [
            {
                path: "/dashboard", component: () => import("@/modules/pais-template/views/pages/dashboard/Dashboard.vue")
            },
            {
                path: '/user', component: () => import("@/modules/pais-template/views/pages/user/User.vue"),
                children: [
                {
                    path: 'profile', component: () => import("@/modules/pais-template/views/pages/user/profile/Profile.vue"),
                    children: [
                        {
                            path: 'overview',
                            name: 'user-profile-tab-overview',
                            component: () => import('@/' + pathPrefix + '/user/profile/tabs/overview/Overview.vue')
                        },
                        {
                            path: 'settings',
                            name: 'user-profile-tab-settings',
                            component: () => import('@/' + pathPrefix + '/user/profile/tabs/settings/Settings.vue')
                        },
                        {
                            path: 'security',
                            name: 'user-profile-tab-security',
                            component: () => import('@/' + pathPrefix + '/user/profile/tabs/security/Security.vue')
                        },
                        {
                            path: 'logs',
                            name: 'user-profile-tab-logs',
                            component: () => import('@/' + pathPrefix + '/user/profile/tabs/logs/Logs.vue')
                        }
                        // { path: '', redirect: '/user/profile/security' },
                        // {
                        //     path: 'account', component: () => import("@/modules/pais-template/views/pages/user/profile/tabs/AccountInformation.vue")
                        // },
                        // {
                        //     path: 'security', component: () => import("@/modules/pais-template/views/pages/user/profile/tabs/Security.vue")
                        // }
                    ]
                }
                ]
            },
            // Administration
            {
                path: '/admin', component: () => import("@/modules/pais-template/views/pages/admin/Admin.vue"),
                children: [
                    {
                        path: 'general-settings', component: () => import("@/modules/pais-template/views/pages/admin/settings/General.vue"),
                        meta: {
                            anyOf: ['superadmin', 'developer'], onDeniedRoute: '/unauthorized'
                        },
                    },
                    {
                        path: 'announcement', component: () => import("@/modules/pais-template/views/pages/admin/announcement/Announcements.vue"),
                        meta: {
                            anyOf: ['superadmin', 'developer'], onDeniedRoute: '/unauthorized'
                        },
                        children: [
                            { path: '', redirect: '/admin/announcement/list' },
                            {
                                path: 'list', component: () => import("@/modules/pais-template/views/pages/admin/announcement/tabs/List.vue"),
                                meta: { pageTitle: 'Announcements', breadcrumbs: ['Administration', 'List'] },
                            },
                            {
                                path: 'archive', component: () => import("@/modules/pais-template/views/pages/admin/announcement/tabs/Archive.vue"),
                                meta: { pageTitle: 'Announcements', breadcrumbs: ['Administration', 'Archived'], permissions: ['superadmin'] },
                            }
                        ]
                    },
                    {
                        path: 'user', component: () => import("@/modules/pais-template/views/pages/admin/user/User.vue"),
                        meta: {
                            anyOf: ['superadmin', 'developer'], onDeniedRoute: '/unauthorized'
                        },
                        children: [
                        {
                            path: 'management', component: () => import("@/modules/pais-template/views/pages/admin/user/management/Management.vue"),
                            children: [
                                { path: '', redirect: '/admin/user/management/list' },
                                {
                                    path: 'list', component: () => import("@/modules/pais-template/views/pages/admin/user/management/tabs/List.vue"),
                                    meta: { pageTitle: 'List of all registered personnel', breadcrumbs: ['Administration', 'User Management'] },
                                },
                                {
                                    path: 'invited', component: () => import("@/modules/pais-template/views/pages/admin/user/management/tabs/Invited.vue"),
                                    meta: { pageTitle: 'List of invited via email', breadcrumbs: ['Administration', 'User Management'] },
                                },
                                {
                                    path: 'expired-password', component: () => import("@/modules/pais-template/views/pages/admin/user/management/tabs/ExpiredPassword.vue"),
                                    meta: { pageTitle: 'List of personnel expired password', breadcrumbs: ['Administration', 'User Management'] },
                                },
                                {
                                    path: 'archive', component: () => import("@/modules/pais-template/views/pages/admin/user/management/tabs/Archive.vue"),
                                    meta: { pageTitle: 'List of archived users', breadcrumbs: ['Administration', 'User Management'] },
                                }
                            ]
                        }
                        ]
                    },
                    {
                        path: 'modules', component: () => import("@/modules/pais-template/views/pages/admin/modules/Modules.1.vue"),
                        // Access rights
                        meta: {
                            anyOf: ['superadmin', 'developer'],
                            onDeniedRoute: '/unauthorized'
                        },
                        children: [
                            { path: '', redirect: '/admin/modules/list' },
                            {
                                path: 'list', component: () => import("@/modules/pais-template/views/pages/admin/modules/tabs/ListWrapper.vue"),
                                meta: { pageTitle: 'Modules', breadcrumbs: ['Administration', 'List'] },
                                children: [
                                    {
                                        path: ':moduleId',
                                        component: () => import('@/' + pathPrefix + '/admin/modules/tabs/content/Content.vue'),
                                        children: [
                                            {
                                                path: 'roles',
                                                component: () => import('@/' + pathPrefix + '/admin/modules/tabs/content/views/roles/Wrapper.vue'), 
                                                children: [
                                                    {
                                                        path: 'list', 
                                                        component: () => import('@/' + pathPrefix + '/admin/modules/tabs/content/views/roles/tabs/WrapperList.vue'),
                                                        meta: { pageTitle: 'Roles', breadcrumbs: ['Administration', 'List of roles'] },
                                                        children: [
                                                            {
                                                                path: ':roleId',
                                                                component: () => import('@/' + pathPrefix + '/admin/modules/tabs/content/views/role/Wrapper.vue'),
                                                                meta: { pageTitle: 'Role', breadcrumbs: ['Administration', 'List of roles', 'role selected'] },
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'archive', component: () => import("@/modules/pais-template/views/pages/admin/modules/tabs/Archive.vue"),
                                meta: { pageTitle: 'Modules', breadcrumbs: ['Administration', 'Archived'] },
                            }
                        ]
                    },
                    {
                        path: 'teams', component: () => import("@/modules/pais-template/views/pages/admin/teams/Teams.vue"),
                        meta: {
                            anyOf: ['superadmin', 'developer'], onDeniedRoute: '/unauthorized'
                        },
                        children: [
                            { path: '', redirect: '/admin/teams/list' },
                            {
                                path: 'list', component: () => import("@/modules/pais-template/views/pages/admin/teams/tabs/List.vue"),
                                meta: { pageTitle: 'Teams', breadcrumbs: ['Administration', 'List'] },
                            },
                            {
                                path: 'archive', component: () => import("@/modules/pais-template/views/pages/admin/teams/tabs/Archive.vue"),
                                meta: { pageTitle: 'Teams', breadcrumbs: ['Administration', 'Archived'], permissions: ['superadmin'] },
                            }
                        ]
                    },
                ]
            },
            // Unauthorized
            {
                path: '/unauthorized',
                component: () => import("@/modules/pais-template/views/error/NoRights.vue")
            }
        ],    
    },
    {
        path: '/auth', component: () => import("@/modules/pais-template/views/auth/fancy/Wrapper.vue"),
        children: [
            { path: "sign-in", name: 'sign-in', component: () => import("@/modules/pais-template/views/auth/fancy/SignIn.vue") },
            { path: "sign-up/", name: 'sign-up', component: () => import("@/modules/pais-template/views/auth/fancy/SignUp.vue") },
            { path: "password-reset", name: 'password-reset', component: () => import("@/modules/pais-template/views/auth/fancy/PasswordReset.vue") },
            { path: "two-steps", name: 'two-steps', component: () => import("@/modules/pais-template/views/auth/fancy/TwoSteps.vue") },
            { path: "reset-password/:token/:email", name: 'reset-password', component: () => import("@/modules/pais-template/views/auth/NewPassword.vue") },
            { path: 'registered/:token/:email', name: 'registered', component: () => import("@/modules/pais-template/views/auth/fancy/Registered.vue") },
            { path: 'verify-email/:email/:token', name: 'verify-email', component: () => import("@/modules/pais-template/views/auth/fancy/VerifyEmail.vue") },
            { path: 'lock', name: 'lock', component: () => import("@/modules/pais-template/views/auth/Lock.vue") }
        ]
    },
    // Catched url to be redirected
    {
        path: '/reset-password/:token/:email',
        name: 'reset-password-guest',
        component: () => import("@/modules/pais-template/views/auth/Redirect.vue")
    },
    {
        path: '/verify/:email/:token',
        name: 'verify-guest',
        component: () => import("@/modules/pais-template/views/auth/Redirect.vue")
    },
    {
        path: '/users/set_user/:id/:token',
        name: 'set-user-guest',
        component: () => import("@/modules/pais-template/views/auth/Redirect.vue")
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/modules/pais-template/views/error/Error404.vue")
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404"
    }
];

if (ModuleConfig.modules.length > 0) {  
    for (let index = 0; index < ModuleConfig.modules.length; index++) {
        const module = ModuleConfig.modules[index];
        /******* begin:: INCLUDE MODULE ROUTES HERE ********/
        //if (module.moduleName === 'sample') {
        if (module.children) {
            routes[0].children?.push({
                path: module.route,
                component: () => import('@/modules/pais-template/router/Index.vue'),
                children: module.children
            })
        }
    }
}


    
const router = createRouter({
    history: createWebHistory(),
    routes
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
router.beforeEach((to: any, from: any, next: any) => {
    // current page view title
    //document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

    // Check only once before browser reload.
    if (
        !store.getters.isUAuthenticated &&
        to && 
        JwtService.preventRoutes.indexOf(to.name) === -1
    ) {     
        store.dispatch(Actions.VERIFY_AUTH, { data: {
            refresh_token: JwtService.getRToken()
        }, callback: next });
    } else {
        next();
    }

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);    
    
    // Scroll page to top on every route change
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
});


export default router;