import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';
import JwtService from "@/modules/pais-template/core/services/JwtService";
import router from "@/modules/pais-template/router";

@Module
export default class UserModule extends VuexModule {    
    @Action
    [Actions.ADMIN_USER_INVITES] (payload): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/users/show-invites?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_UPDATE_DETAILS] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/api/users/update/' + payload.id, payload).then(({ data }) => {
                if (data) {
                    // check if the user needs to automatically logout
                    if (data.logout != undefined && data.logout === true) {
                        // purge auth.
                        this.context.commit(Mutations.PURGE_AUTH);
                        // redirect to login
                        router.replace('/auth/sign-in');
                    } else {
                        // Update current user
                        this.context.commit(Mutations.SET_USER, data.data);
                    }                                        
                }
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_GET_RIGHTS] (payload: { userId }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/users/show-roles/' + payload.userId).then(({ data }) => {                
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_RESEND_EMAIL_VERIFICATION] (payload): Promise<void> {
        // for verification
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/resend-email-verification', payload).then(({ data }) => {                
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
    
    @Action
    [Actions.USER_UPDATE_PHOTO] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/upload_image', payload).then(({ data }) => {                
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_REGISTERED_EMAIL_VERIFICATION] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/verify/' + payload.email + '/' + payload.token + '?' + payload.query).then(({ data }) => {
                console.log(data);
                // if (data.auth_status) {
                //     // Remove Login Token
                //     JwtService.destroyLoginToken();
                //     this.context.commit(Mutations.SET_AUTH, data);
                // } else {
                //     // Save Login token for two factor verification
                //     JwtService.saveLoginToken(data);
                // }
                if (data && data.type) {
                    JwtService.destroyLoginToken();
                    JwtService.destroyToken();
                }
                resolve(data);
                
                
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_REGISTERED_RESEND_EMAIL] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/resend-email-verification', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.USER_REGISTER] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/register', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) })
        });
    }
}