import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';

@Module
export default class AppComponentSignatoryModule extends VuexModule {
    @Action
    [Actions.APP_COMPONENT_SIGNATORY_PERSONNEL_SEARCH] (payload: { 
        api: string, isPreload: boolean, data: Record<string, unknown> | undefined, searchApi: string,
        pagination, search
    }): Promise<void> {
        if (!payload.searchApi) {
            payload.searchApi = '/api/v1/mpis/orderpub-serial-search?' + FormUtil.objectToString({...payload.pagination, ...payload.search});
        }
        return new Promise<void>((resolve, reject) => {
            let apiType = "post";
            let data = payload.data;
            if (payload.api && payload.isPreload) {
                apiType = "get";
                data = undefined;
            }

            ApiService[apiType](payload.searchApi, data).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
}