import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JwtService from "@/modules/pais-template/core/services/JwtService";
import router from "@/modules/pais-template/router";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
export class FormObject {
    public static urlencode(str) {
    str = (str + '').toString();

    // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
    // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
    return encodeURIComponent(str)
        .replace('!', '%21')
        .replace('\'', '%27')
        .replace('(', '%28')
        .replace(')', '%29')
        .replace('*', '%2A')
        .replace('%20', '+');
    }
    
}
export class FormUtil {        
    public static responseErrCatch(response: {message, status, data}, callback = Function()): void {
        if (response && response.data) {
            Swal.fire({
                title: 'Error Details', html: this.swalMessage(response.data), icon: 'error',
                customClass: { 
                    confirmButton: "btn btn-light-danger",
                    actions: 'mb-0',
                    content: 'margin-top'
                },               
            }).finally(() => {
                if (response && response.status === 401) {
                    // Unauthorized
                    // Remove Login Token
                    JwtService.destroyLoginToken();
                    router.replace('/auth/sign-in');
                } else if (response && response.data && response.data.is_lock) {
                    // Lock acccount
                    const curRoute = router.currentRoute;
                    const valRoute = curRoute.value;  
                    const path = valRoute.fullPath;
                    router.replace('/auth/lock?redirect=' + path);
                }
                if (callback !== undefined && typeof(callback) === 'function') { callback(); }
            })
        } else if (response && (response.message === "Unauthenticated." || response.message === "Unauthenticated")) {
            // If the error detects Unauthenticated
            Swal.fire({ 
                html: 'Unauthenticated',
                icon: "error",
                buttonsStyling: false, confirmButtonText: "Login instead!",
                customClass: { confirmButton: "btn btn-light-danger" }
            }).finally(() => {
                // Unauthorized
                // Remove Login Token
                JwtService.destroyLoginToken();
                router.replace('/auth/sign-in');
            });
        }
    }

    public static swalMessage(err: {message}): string {
        if (err !== undefined) {
            if (typeof(err.message) === 'object') {
                let html = '';
                for (const k in err.message) {
                    html += '<div class="d-flex flex-column align-items-start">';
                    html += '<span class="text-capitalize my-2">' + k + ':</span>';
                    html += '<div class="w-100 alert alert-danger mb-0 d-flex flex-column align-items-start" role="alert"><ul class="list-group">';
                        for (let index = 0; index < err.message[k].length; index++) {
                            let border = '';
                            if ((index + 1) === err.message[k].length) { border = 'border-bottom-0'; }
                            html += '<li style="text-align: left;" class="list-group-item bg-transparent ' + border + ' border-top-0 border-right-0 border-left-0">' + err.message[k][index] + '</li>';
                        }
                            
                    html += '</ul></div>';
                    html += '</div>';
                }
                return html;
            }

            return err.message;
        } else { return 'Undefined Error'; }
    }

    public static toggleLoading(submitButton: HTMLElement | null | undefined, isActivate: boolean): void {
        if (submitButton) {

            if (isActivate) {
                // Activate indicator
                submitButton.setAttribute("data-kt-indicator", "on")

                // Disable button
                submitButton.setAttribute("disabled", "true");
            } 

            // Form wrapper
            const form = submitButton.closest('form');
            if (form) {
                 // disabled all buttons
                const buttons = form.querySelectorAll('button');
                let i;
                for (i = 0; i < buttons.length; i++) { buttons[i].disabled = isActivate; }
                // disabled all input
                const inputs = form.querySelectorAll('input'); let x;
                for (x = 0; x < inputs.length; x++) { inputs[x].disabled = isActivate }
                // disabled all select
                const selects = form.querySelectorAll('select'); let y;
                for (y = 0; y < selects.length; y++) { selects[y].disabled = isActivate }
            }
           
            
            if (!isActivate) {
                //Deactivate indicator
                submitButton?.removeAttribute("data-kt-indicator");
                // Disable button
                submitButton?.removeAttribute("disabled");
            }
            
        }
    }

    /**
     * Swal enterPasswordPrompt password getters popup form
     * 
     * @param text text content
     */
    public static enterPasswordPrompt(callback = Function(), _config = {}): void {
        if (_config === undefined || typeof(_config) !== 'object') { _config = {}; }
        const config = {
                title: 'We need to confirm your password',
                input: "password",
                inputValue: '',
                inputPlaceholder: 'Please input your password',               
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                allowOutsideClick: false,
                ..._config
            };
        Swal.fire({
            ...config,
            inputValidator: (value) => {
                if (!value) { return 'You need to enter your password' }
            },
            onOpen: () => Swal.getConfirmButton().focus()
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        })
    }


    /**
     * Swal error on form submit
     * 
     * @param text text content
     */
    public static swalError (text: string, callback  = Function()): void {        
        Swal.fire({ 
            html: text,
            icon: "error",
            buttonsStyling: false, confirmButtonText: "Try again!",
            customClass: { confirmButton: "btn btn-light-danger" },
            allowOutsideClick: false
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        });
    }

    public static swalSuccess (text: string, callback  = Function()): void {
        Swal.fire({
            title: '', text, icon: 'success', customClass: { confirmButton: "btn btn-light-success" }
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        });
    }

    public static swalInfo (text: string, callback = Function()): void {
        Swal.fire({
            title: '', text, icon: 'info', customClass: { confirmButton: "btn btn-light-info" },
            allowOutsideClick: false
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        });
    }
   
    public static swalHeadSake () {
        const popup = Swal.getPopup();
        popup?.classList.remove('swal2-show')
        setTimeout(() => {
            popup?.classList.add('animate__animated', 'animate__headShake')
        })
        setTimeout(() => {
            popup?.classList.remove('animate__animated', 'animate__headShake')
        }, 500)
        return false;
    }

    /**
     * swalWarning
     * @param type 'softDelete' | 'archive' | 'delete' | 'restore',
     * @param callback Function
     * @param api { url: string; params?: {} },
     * @param text Custom text
     */
    public static swalWarning(
        type: 'softDelete' | 'archive' | 'delete' | 'restore' | 'trash',
        callback = Function(),
        api: { 
            url: string; 
            params?: Record<string, unknown>;
            type?: 'put' | 'delete' | 'post' | 'get' | undefined;
        } = { 
            url: '', 
            params: {}, 
            type: undefined 
        },
        _config: string | Record<string, unknown> = 'Are you sure ?'
    ): void {
        if (api !== undefined && api !== null && api && api.url !== '') {
            // Define api service type by type.
            let APISERVICE_TYPE;
            let confirmButtonText;
            let title;
            let confirmButtonClass = 'btn ';
            if (type === 'softDelete') { 
                APISERVICE_TYPE = 'delete'; 
                confirmButtonText = 'Yes Delete!';
                title = 'Confirm Delete';
                confirmButtonClass += 'btn-danger';
            } else if(type === 'archive') {
                APISERVICE_TYPE = 'delete';
                confirmButtonText = 'Yes Archive!',
                title = 'Confirm Archive';
                confirmButtonClass += 'btn-warning';
            } else if (type === 'delete') {
                APISERVICE_TYPE = 'delete'; 
                confirmButtonText = 'Yes Delete!';
                title = 'Confirm Permanent Delete';
                confirmButtonClass += 'btn-danger';
                _config = 'This will be deleted immediately. You can\'t undo this action.';
            }
            else if (type === 'restore') { 
                APISERVICE_TYPE = 'put'; 
                confirmButtonText = 'Yes Restore!';
                title = 'Confirm Restore';
                confirmButtonClass += 'btn-success';
            }
            else if(type === 'trash') {
                APISERVICE_TYPE = 'delete';
                confirmButtonText = 'Yes Trash!',
                title = 'Confirm Trash';
                confirmButtonClass += 'btn-danger';
            }

            // If api url type is defined
            if (api.type !== undefined) {
                APISERVICE_TYPE = api.type;
            }

            // swal option config
            let config = {};
            if (typeof _config === "string") {
                config['text'] = _config;
            } else { config = _config }

            Swal.fire({
                title,
                icon:"warning",showCancelButton:!0,buttonsStyling:!1,
                confirmButtonText,cancelButtonText:"No, cancel",
                customClass:{confirmButton: confirmButtonClass,cancelButton:"btn btn-active-light-primary"},
                allowOutsideClick: this.swalHeadSake, showLoaderOnConfirm: true,
                ...config,
                preConfirm: () => {
                    return ApiService[APISERVICE_TYPE](api.url, api.params)
                        .catch(({ response }) => { 
                            FormUtil.responseErrCatch(response); 
                        });
                }
            }).then((result) => {
                if (typeof(callback) === 'function') { callback(result); }
            })
        }
    }

    public static confirmation(
        callback = Function(),
        _config: string | Record<string, unknown>) : void {

        // swal option config
        let config = {};
        if (typeof _config === "string") {
            config['text'] = _config;
        } else { config = _config }

        Swal.fire({
            title: "Are you sure?",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            cancelButtonText:"No, cancel",
            allowOutsideClick: this.swalHeadSake,
            showLoaderOnConfirm: true,
            ...config,
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        })
    }

    // Adding of comment
    public static commentConfirmation(
        required: boolean,
        callback = Function(),
        api: {
            url: string;
            data?;
            type: 'put' | 'delete' | 'post' | 'get';
        } = { url: '', type: 'get' },
        _config: string | Record<string, unknown> = 'You can add optional comment'
    ): void {
        // swal option config
        let config = {};
        if (typeof _config === "string") {
            config['text'] = _config;
        } else { config = _config }

        Swal.fire({
            title: "Add Comment",
            icon:"warning",showCancelButton:!0,buttonsStyling:!1,
            confirmButtonText: "Proceed",cancelButtonText:"No, cancel",
            customClass:{confirmButton: "btn-success",cancelButton:"btn btn-active-light-primary"},
            allowOutsideClick: this.swalHeadSake, showLoaderOnConfirm: true,
            ...config,
            preConfirm: () => {
                return ApiService[api.type](api.url, api.data)
                .catch(({ response }) => { 
                    FormUtil.responseErrCatch(response); 
                });
            }
        }).then((result) => {
            if (typeof(callback) === 'function') { callback(result); }
        })
    }
   

    public static objectToString(query: Record<string, unknown>, delimeter = '&'): string {
        let rn = '';
        if(query) {
            let str = '';            
            for (const k in query) {
                if (str != '') { str += delimeter; }
                str += k + '=' + query[k];
            }
            rn = str; 
        }
        return rn;
    }
}