import { computed, reactive } from 'vue';
import { User, State, PluginOption, Ability, AbilityArgs, AnyFunction, HelperName } from './types';
import { PluginOptionWithDefaults, AbilitiesEvaluationProps, AsyncUser, RuleSetter, Acl } from './types/acl';
import { capitalize, getFunctionArgsNames } from './utils';
import JwtService from "@/modules/pais-template/core/services/JwtService";
// plugin global state
const state = reactive({
  registeredUser: {},
  registeredRules: {},
  options: {},
} as State<unknown>);

/**
 * Register plugin options to state
 * @param pluginOptions 
 * @return void
 */
const registerPluginOptions = <U = User>(pluginOptions: PluginOptionWithDefaults<U>): void => {
    // Init and set user to state
    if (hasAsyncUser(pluginOptions.user)) {
        state.registeredUser = pluginOptions.user();
    } else {
        const user: any = pluginOptions.user;
        state.registeredUser = user;
    }   
    // Run and init the defined rules
    if (pluginOptions.rules && typeof pluginOptions.rules === "function") {
        pluginOptions.rules();
    }
    // Set other user defined plugins to state
    state.options = pluginOptions;
}

const evaluateAbilityPermissions = (ability: Ability): boolean => {
    const permissions = state.registeredUser.permissions;
    if (permissions) {
        const perm = permissions.filter((v) => {
            
            if (ability && typeof(ability) === 'string') {
                return v === ability;
                
            } else if (ability && typeof(ability) === 'object') {
                // object
                return ability.indexOf(v) !== -1;
            }

            return false;
        });
        return perm.length && perm.length >= 0 ? true : false;
    }
    return false;
}

/**
 * Add an ability and its callback to rules state
 * @param ability
 * @param callback 
 * @return void 
 */
const addAclAbility = (ability: string, callback: AnyFunction): void => {
    if (!Object.prototype.hasOwnProperty.call(state.registeredRules, ability)) {
        state.registeredRules[ability] = callback;
    } else {
        console.warn(`Duplicate ACL Rule '${ability}' defined. Only the first defined rule will be evaluated.`)
    }
}

/**
 * Set an ACL Rule
 * @param abilities 
 * @param callback 
 * @return void
 */
const setRule = (abilities: Ability, callback: AnyFunction): void => {
    
    if (typeof abilities === "string") {
        addAclAbility(abilities, callback);
    }
    // if (typeof abilities === "string") {
    //     addAclAbility(abilities, callback);
    // } else if (typeof abilities === "object" && Array.isArray(abilities)) {     
    //     Object.values(abilities).forEach((ability) => {
    //     addAclAbility(ability, callback);
    //     });
    // }
};

/**
 * Evaluate ability check
 * @param ability
 * @param abilityCallback
 * @param args arguments
 * @return boolean
 */
const evaluateAbilityCallback = (abilityCallback: any, ability: Ability, args?: AbilityArgs): boolean => {
    try {
        if (typeof abilityCallback === 'function') {
            // for setrules permissions
            if (typeof args === 'object' && !Array.isArray(args)) {
                return abilityCallback(state.registeredUser, args);
            } else if (typeof args === 'object' && Array.isArray(args)) {
                return abilityCallback(state.registeredUser, ...args);
            } else {
                return abilityCallback(state.registeredUser);
            }  
        } else if (typeof abilityCallback === 'object') {
            // for permissions
            return abilityCallback.callback(ability);
        }
        return false;
    } catch (error) {
        // Prepare an error message
        // Get the $can args to be passed from the callback function string
        const callbackArgsNames = getFunctionArgsNames(abilityCallback);
        let StrCallbackArgsNames: string | null = null;

        if (callbackArgsNames && Array.isArray(callbackArgsNames)) {
        callbackArgsNames.shift(); // Remove the first ever arg from the args array i.e normally the 'user' arg
            StrCallbackArgsNames = callbackArgsNames.join(', '); // join the arrays back to str after removing user arg
        }
            let customErrorMessage = 'The defined ACL Rule for "' + ability + '" require some argument(s) or data object to be specified for matching.';
            customErrorMessage += '\n\nCheck the file containing your defineAclRules((setRule) => {...}); declarations';
            customErrorMessage += '\n\nExamples:';
        if (callbackArgsNames && callbackArgsNames.length <= 0) {
            customErrorMessage += `\nv-can:${ability}`;
            customErrorMessage += `\nv-can="'${ability}'"`;
            customErrorMessage += `\n$can('${ability}')`;
        } else if (callbackArgsNames && callbackArgsNames.length === 1) {
            customErrorMessage += `\nv-can:${ability}="${StrCallbackArgsNames}"`;
            customErrorMessage += `\nv-can="'${ability}', ${StrCallbackArgsNames}"`;
            customErrorMessage += `\n$can('${ability}', ${StrCallbackArgsNames})`;
        } else {
            customErrorMessage += `\nv-can:${ability}="[${StrCallbackArgsNames}]"`;
            customErrorMessage += `\nv-can="'${ability}', [${StrCallbackArgsNames}]"`;
            customErrorMessage += `\n$can('${ability}', [${StrCallbackArgsNames}])`;
        }
        console.error(customErrorMessage);
        console.error(error);
        return false;
    }   
}

/**
 * Check ACL Abilities
 * @param object 
 * @return boolean
 */
const checkAclAbilities = ({ abilities, args, any = false, helperName }: AbilitiesEvaluationProps): boolean => {
    if (abilities && typeof abilities === 'string') {
        if (Object.prototype.hasOwnProperty.call(state.registeredRules, abilities)) {
            // This is for registered rule set on main.ts
            const callback = state.registeredRules[abilities];
            return evaluateAbilityCallback(callback, abilities, args);
        } else {
            // No registered rule set then check on permissions on the user
            const callback = evaluateAbilityPermissions;
            return evaluateAbilityCallback({callback}, abilities, args);
        }
    } else if (typeof abilities === 'object' && Array.isArray(abilities)){
        let callbackResponse = false;
        let validCount = 0;
        let checkStatus = false;
        let counter = 0;
        // these are for permissions that has object 
        abilities.forEach((ability) => {
            
            if (Object.prototype.hasOwnProperty.call(state.registeredRules, ability.abilities)) {
                const callback = state.registeredRules[ability.abilities];
                callbackResponse =  evaluateAbilityCallback(callback, ability.abilities, ability.args);
            } else {                
                // this will execute the custom check of ability callback
                const callback = evaluateAbilityPermissions;
                callbackResponse = evaluateAbilityCallback({callback}, ability.abilities, args);
            }
            if (callbackResponse) { validCount++; }
            counter++;
        });
        if (helperName === 'anyOf' && validCount >= 1) {
            // If any of ability is true then return true
            checkStatus = true;
        } else if (counter > 0 && counter === validCount) {
            checkStatus = true;
        }
        return checkStatus;
    }
    return false;
};

/**
 * Prepare ACL Check
 * @param object
 * @return boolean 
 */
const prepareAcl = ({ abilities, args, any = false, helperName }: AbilitiesEvaluationProps): boolean => {
    const aclAbilities = abilities;
    const aclArgs = args;
    const anyModifier = any;

    let aclStatus = false;
    if (aclAbilities) {
        if (aclArgs) {
            // $can('sample-read', 'argument') || $acl.permissions()
            aclStatus = checkAclAbilities({ abilities: aclAbilities, args: aclArgs, helperName });
        } else {
            // $can('sample-read')
            aclStatus = checkAclAbilities({ abilities: aclAbilities, helperName });
        }
    } else {
        if (aclArgs && aclArgs !== null && typeof aclArgs === 'object') {
            // v-can="['orderpub-management-create']" OR $can(['orderpub-management-create'])
            const argsCount = (Array.isArray(aclArgs)) ? aclArgs.length : Object.keys(aclArgs).length;
            const abilityList: any[] = [];
            const argList: any[] = [];
            aclArgs.forEach((ability) => {
                if (ability && typeof ability === 'string') {
                    abilityList.push({ abilities: ability });
                }
            });
            aclStatus = checkAclAbilities({ abilities: abilityList, args: argList, any: anyModifier, helperName });
        }
    }
    return aclStatus;
}

/**
 * Parse helper arguments to Prepare ACL
 * @param object
 * @return {boolean} 
 */
const helperArgsToPrepareAcl = ({ abilities, args, any = false, helperName }: AbilitiesEvaluationProps): boolean => {
    if (abilities && typeof abilities === 'string') {
        return prepareAcl({
            abilities: abilities,
            args: args,
            any: any,
            helperName
        });     
    } else if (typeof abilities === 'object') {     
        return prepareAcl({
            abilities: null,
            args: abilities, // Parse abilities as args since the specified value of abilities is object/array
            any: any,
            helperName
        });
    }
    console.warn('Invalid ACL arguments specified.')
    return false;
}

/**
 * anyOf Helper Handler
 * @param abilities 
 * @param args arguments
 * @return boolean 
 */
const anyOfHelperHandler = (abilities: Ability, args?: AbilityArgs, helperName = 'anyOf'): boolean => {
  return helperArgsToPrepareAcl({ abilities: abilities, args: args, any: false, helperName });
}

/**
 * can Helper Handler
 * @param abilities 
 * @param args arguments
 * @return boolean 
 */
const canHelperHandler = (abilities: Ability, args?: AbilityArgs, helperName?: HelperName): boolean => {
  return helperArgsToPrepareAcl({ abilities: abilities, args: args, any: false, helperName });
}

/**
 * Checks if the user has an async getter
 * @param  {U|AsyncUser<U>} user
 * @returns boolean
 */
const hasAsyncUser = <U = User>(user: U | AsyncUser<U>): user is AsyncUser<U> => {
    return typeof user === 'function';
};

/**
 * Install the plugin
 * @param app 
 * @param options 
 * @return void
 */
export const installPlugin = <U = User>(app: any, options?: PluginOption<U>) => {
    const isVue3 = !!app.config.globalProperties;
    const defaultPluginOptions: PluginOptionWithDefaults<U> = {
        user: Object.create(null),
        rules: null,
        router: null,
        onDeniedRoute: '/',
        directiveName: 'can',
        helperName: '$can',
        enableSematicAlias: true
    }

    const pluginOptions: PluginOptionWithDefaults<U> = { ...defaultPluginOptions, ...options };

    // Sanitize directive name should the developer specified a custom name
    if (pluginOptions.directiveName && typeof pluginOptions.directiveName === "string") {
        if (pluginOptions.directiveName.startsWith('v-')) {
            pluginOptions.directiveName = pluginOptions.directiveName.substring(2, pluginOptions.directiveName.length);
        }      
    }

    // Sanitize helper name should the developer specified a custom name
    if (pluginOptions.helperName && typeof pluginOptions.helperName === "string") {
        if (pluginOptions.helperName.charAt(0) !== '$') {
            pluginOptions.helperName = '$' + pluginOptions.helperName;
        }      
    }

    // directive handler function
    const directiveHandler = (el: any, binding: any) => {
        const aclAbilities = binding.arg;
        const aclArgs = binding.value;
        const aclModifiers = binding.modifiers;
        const helperName = undefined;    

        const anyModifier = (aclModifiers.any) ? true : false;
        const notModifier = (aclModifiers.not) ? true : false;
        const readonlyModifier = (aclModifiers.readonly) ? true : false;
        const disableModifier = (aclModifiers.disable || aclModifiers.disabled) ? true : false;
        const hideModifier = (aclModifiers.hide || aclModifiers.hidden) ? true : false;
        
        // call to prepare ACL and check abilities
        const aclStatus = prepareAcl({ abilities: aclAbilities, args: aclArgs, any: anyModifier, helperName });
        if (aclStatus) {
            // ACL check is validm apply valid effect

            // reverse the valid effect
            if (notModifier) {
                el.style.display = 'none'; 
            }
        } else {
                // v-can:edit-post.disabled="post"
            if (notModifier) {
                // reverse the invalid effect
            } else {
                // apply invalid effect
                if (disableModifier) {
                el.disabled = true;
                } else if (readonlyModifier) {
                el.readOnly = true;
                } else if (hideModifier) {
                el.style.display = 'none';
                } else {
                el.style.display = 'none';
                }
            }            
        }
    }

    const registerDirective = (app: any, name: string, isVue3: boolean) => {
        if (isVue3) {
            app.directive(`${name}`, {
                mounted(el: any, binding: any) {
                    directiveHandler(el, binding);
                },
                updated(el: any, binding: any) {
                    directiveHandler(el, binding);
                }
            });
        } else {
            app.directive(`${name}`, {
                mounted(el: any, binding: any) {
                    directiveHandler(el, binding);
                },
                updated(el: any, binding: any) {
                    directiveHandler(el, binding);
                }
            });
        }
    }

    const registerHelper = (app: any, name: string, isVue3: boolean, isAlias: boolean) => {
        // Add a global '$can' or '$anycustomname' function | app.config.globalProperties.$can
        // Add a global '$can.not' or '$anycustomname.not' function | app.config.globalProperties.$can.not
        // Add a global '$can.any' or '$anycustomname.any' function | app.config.globalProperties.$can.any
        // add a global '$acl.can'  or '$acl.anyCan', etc
        if (isVue3) { // Vue 3
            if (isAlias) {
                if (!app.config.globalProperties.$acl) {
                    app.config.globalProperties.$acl = {};
                }
                app.config.globalProperties.$acl[name] = (abilities: Ability, args?: AbilityArgs) => canHelperHandler(abilities, args, name);
            } else {
                app.config.globalProperties[name] = (abilities: Ability, args?: AbilityArgs) => canHelperHandler(abilities, args);
            }        
        }
    }

    // DIRECTIVES
    registerDirective(app, `${pluginOptions.directiveName}`, isVue3);
    // DIRECTIVE Sematic Aliases
    if (pluginOptions.enableSematicAlias) {
        registerDirective(app, 'permission', isVue3);
    }
    // HELPER FUNCTION / METHOD
    registerHelper(app, `${pluginOptions.helperName}`, isVue3, false);
    if (pluginOptions.enableSematicAlias) {
        registerHelper(app, 'permission', isVue3, true);
        registerHelper(app, 'permissions', isVue3, true);
        registerHelper(app, 'anyOf', isVue3, true);
    }

    // Vue raouter middleware evalutions
    if (pluginOptions.router) {

        const routerRedirectHandler = (to: any, from: any, next: any, granted: any) => {
            if (granted) {
                next();
            } else {
                // if not granted
                let onDeniedRoute = pluginOptions.onDeniedRoute;
                if (to.meta && to.meta.onDeniedRoute) {
                    onDeniedRoute = to.meta.onDeniedRoute;
                }
                console.log(typeof onDeniedRoute);
                if (typeof onDeniedRoute === 'object') {
                    // not configured
                } else if (typeof onDeniedRoute === 'function'){
                    // function
                    console.log(to);
                    next({ 
                        path: onDeniedRoute(to),
                        replace: true,
                        query: { previous : from.fullPath }
                    });
                } else {
                    if (onDeniedRoute === '$from') {
                        next(from)
                    } else {
                        next({ 
                            path: onDeniedRoute,
                            replace: true,
                            query: { previous : from.fullPath }
                        });
                    }
                }
            }
        }

        const evaluateRouterAcl = (to: any, from: any, next: any) => {
            if (to.meta && (to.meta.can || to.meta.permissions || to.meta.permission || to.meta.anyOf)) {
                const abilities = (to.meta.can || to.meta.permission || to.meta.permission || to.meta.anyOf);
                let ability: undefined | string = undefined;

                if (to.meta.anyOf) { ability = 'anyOf' }

                let granted = false;
                if (typeof abilities === 'function') {
                    // not yet configured on function type of abilities
                } else {    
                    granted = canHelperHandler(abilities, undefined, ability);
                }
                routerRedirectHandler(to, from, next, granted);
            } else {
                // Proceed to request route if no can|canNot|CanAny meta is set                
                next();
            }
        }

        // vue-router hook
        pluginOptions.router.beforeEach((to: any, from: any, next: any) => {
            if (to && JwtService.preventRoutes.indexOf(to.name) !== -1) {
                // no need of current user if on authroutes
                next();
            } else if (hasAsyncUser(pluginOptions.user)) {
                pluginOptions.user = pluginOptions.user();
                registerPluginOptions(pluginOptions);
                evaluateRouterAcl(to, from, next);
            } else {
                evaluateRouterAcl(to, from, next);
            }
        })
    } else { // No router
        if (hasAsyncUser(pluginOptions.user)) {
            console.error(`Instance of vue-router is required to define 'user' retrieved from a promise or Asynchronous function.`)
        }
    } // ./ Vue Router evaluation
}

/**
 * Create instance of Vue Simple ACL
 * @param userDefinedOptions 
 * @return object
 */
export const createAcl = <U = User>(userDefinedOptions: PluginOption<U>): any => {
  return {
    install: (app: any, options: any = {}) => {
      installPlugin(app, { ...options, ...userDefinedOptions });
    }
  }
}

/**
* Define ACL Rules
* @param aclRulesCallback
* @return void
*/
export const defineAclRules = <U = User>(aclRulesCallback: (setter: RuleSetter<U>) => void): void => {
    if (typeof aclRulesCallback === "function") {
        aclRulesCallback(setRule);
    }
};

/**
* Returns the acl helper instance. Equivalent to using `$can` inside templates.
* @return object
*/
export const useAcl = <U = User>(): Acl<U> => {
    const acl: any = {};
    acl.user = computed(() => state.registeredUser).value;
    acl.can = canHelperHandler;
    acl.permission = canHelperHandler;
    acl.permissions = canHelperHandler;
    acl.anyOf = anyOfHelperHandler;
    return reactive(acl);
}