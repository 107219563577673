<template>
    <vue-final-modal v-slot="{ close }" :name="id" v-bind="$attrs" content-class="modal-content">
        <div class="app-pais-modal-container">        
            <div class="app-pais-modal-overlay-wrapper">
                <div class="app-pais-modal-overlay-pane" :style="paneStyles">
                    <div :style="formModel.modalStyle" :class="formModel.modalClass">
                        <div :class="formModel.modalClass + '-header'">
                            <div class="text text-capitalize"><h4>
                                {{title}}
                            </h4></div>    
                            <button @click="close(), $emit('close', { type: 'cancel' })" class="btn btn-icon btn-default">
                                <i class="las la-times fs-2x"></i>
                            </button>
                        </div>
                        <div class="position-relative" :style="formModel.modalContentStyle" :class="formModel.modalClass + '-content ' + formModel.modalContentClass">
                            <slot name="content"></slot>
                            <!-- <component :is="content"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
export default defineComponent({
    name: 'VModal',
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: ''
        },
        id: {
            type: String
        },
        width: { type: String },
        height: { type: String },
        margin: { type: Boolean, default: true }
    },
    setup(props) {
        const formModel = reactive({
            modalClass: 'app-pais-modal',
            modalStyle: { width: '200px' },
            modalContentClass: '',
            modalContentStyle: {}
        }); 

        let paneStyles = {};

        if (props.width) {
            // eslint-disable-next-line vue/no-setup-props-destructure
            formModel.modalStyle.width = props.width;
        }
        if (props.margin === false) {
            formModel.modalContentClass = 'no-margin';
        }

        if (props.height && props.height === 'fullFixed') {
            paneStyles = { bottom: '30px', top: 0};
        }

        if (props.height && props.height === 'pdfview') {
            paneStyles = { bottom: '30px', top: 0};
            formModel.modalContentStyle = { 
                'display': 'flex', 
                'flex-direction': 'column', 
                'height': '100%',
                'overflow-x': 'auto'
            };
        }

        return { formModel, paneStyles }
    }
})
</script>
<style scoped lang="scss">
    .app-pais-modal-container {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        .app-pais-modal-overlay-wrapper {
            display: flex;
            position: absolute;
            z-index: 1000;                
            left: 0;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            overflow-y:scroll;

            .app-pais-modal-overlay-pane {
                position: absolute;
                pointer-events: auto;
                box-sizing: border-box;
                z-index: 1000;
                display: flex;
                max-width: 100%;
                max-height: 100%;
                margin-top:30px;
                min-height: 150px;

                .app-pais-modal {
                    background:white;
                    // disabled for pdf support
                    //display: block;          
                    // disabled for pdf support      
                    border-radius: 4px;
                    box-sizing: border-box;
                    overflow: auto;
                    outline: 0;
                    width: 100%;
                    height: 100%;
                    min-height: inherit;
                    max-height: inherit;
                    margin-bottom: 30px;
                    position: relative;
                    // added for pdf support
                    display: flex;
                    flex-direction: column;
                    // added for pdf support

                    .app-pais-modal-header {
                        padding: 10px 15px 10px 15px;
                        border-bottom: 1px solid #ccc;
                        display: -webkit-box;
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;
                        justify-content: space-between;
                        min-height: 59px;

                        .text {
                            padding-right: 20px;
                        }

                        h4 {
                            margin-bottom: 0;
                        }

                        button {
                            i {
                                padding-right: 0;
                            }

                            &:hover {
                                background-color: #F3F6F9 !important;
                                border-color: #F3F6F9 !important;
                                i {
                                    color: red !important;
                                }
                            }
                        }
                    }

                    .app-pais-modal-content {
                        margin: 20px;


                        &.no-margin {
                            margin: 0;
                        }
                    }
                }
            }
        }


        .app-pais-modal-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            pointer-events: auto;
            -webkit-tap-highlight-color: transparent;
            transition: opacity .4s cubic-bezier(.25,.8,.25,1);
            opacity: 0;

            &.app-pais-modal-overlay-showing {
                opacity: 1;
            }

            &.app-pais-modal-overlay-backdrop {
                background: rgba(0,0,0,.32);
            }
        }
    }
</style>