import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';
import ApiService from "@/modules/pais-template/core/services/ApiService";

@Module
export default class AppAdminAnnouncementModule extends VuexModule {
    @Action
    [Actions.ADMIN_ANNOUNCEMENTS_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});            
            ApiService.get('/api/announcements?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_ANNOUNCEMENTS_DELETED] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});            
            ApiService.get('/api/announcements/only-trashed?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_ANNOUNCEMENTS_ADDUPDATE] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/announcements', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
}