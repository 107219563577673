// NOTE: PLEASE ADD PREFIX FOR IMPORT TO AVOID CONFLICTS.
// EX: [MODULE_PREFIX_NAME]CommonModule

// COMMON
import MantrixCommonModule from './modules/Common';
// DASHBOARD

// REFERENCES
import MantrixMaterialRefModule from './modules/references/Materials';
import MantrixEquipmentsRefModule from './modules/references/Equipment';
import MantrixLaborTypesRefModule from './modules/references/LaborTypes';
import MantrixLaborMethodsRefModule from './modules/references/LaborMethods';
import MantrixLaborNamesRefModule from './modules/references/LaborNames';
import MantrixLaborHoursRefModule from './modules/references/LaborHours';
import MantrixUnitRefModule from './modules/references/Unit';
import MantrixDurationsRefModule from './modules/references/Durations';
import MantrixDesignationsRefModule from './modules/references/Designations';
import MantrixScopesRefModule from './modules/references/Scopes';
import MantrixSubScopesRefModule from './modules/references/SubScopes';

// TRANSACTION
import MantrixProjectTransModule from './modules/transactions/Projects';
import MantrixDupaCostsTransModule from './modules/transactions/DupaCosts';
import MantrixDupaMaterialCostsTransModule from './modules/transactions/DupaMaterialCosts';
import MantrixDupaLaborCostsTransModule from './modules/transactions/DupaLaborCosts';
import MantrixDupaEquipmentCostsTransModule from './modules/transactions/DupaEquipmentCosts';
import MantrixProjectLaborTransModule from './modules/transactions/Labor';




const MantrixStore = {
    MantrixCommonModule,

    // REFERENCES
    MantrixMaterialRefModule,
    MantrixEquipmentsRefModule,
    MantrixLaborTypesRefModule,
    MantrixLaborMethodsRefModule,
    MantrixLaborNamesRefModule,
    MantrixLaborHoursRefModule,
    MantrixUnitRefModule,
    MantrixDurationsRefModule,
    MantrixDesignationsRefModule,
    MantrixScopesRefModule,
    MantrixSubScopesRefModule,
    
    // TRANSACTION
    MantrixProjectTransModule,
    MantrixDupaMaterialCostsTransModule,
    MantrixDupaLaborCostsTransModule,
    MantrixDupaCostsTransModule,
    MantrixDupaEquipmentCostsTransModule,
    MantrixProjectLaborTransModule,
}

export default MantrixStore;