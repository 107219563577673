import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "../../core/services/ApiService";
import JwtService from "../../core/services/JwtService";
import { Actions, Mutations } from "../enums/StoreEnums";
import router from "@/modules/pais-template/router";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';

@Module
export default class TwoStepAuthModule extends VuexModule {
    @Action
    [Actions.OTP_RESEND] (payload: Record<string, unknown>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/resend-otp', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { FormUtil.responseErrCatch(response); reject(response) })
        });
    }

    @Action
    [Actions.TWO_STEP_AUTH] (payload: { auth_type, data }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let url = '';
            if (payload.auth_type == '1') { url = '/api/otp-challenge'; }
            else if (payload.auth_type === '2') { url = '/api/two-factor-challenge'; }
            ApiService.post(url, payload.data).then(({data}) => {
                if (data && data.type === 1) {
                    // Remove Login Token
                    JwtService.destroyLoginToken();
                    this.context.commit(Mutations.SET_AUTH, data);
                    // Redirect to dashboard
                    router.replace('/dashboard');
                    resolve(data)
                } 
            }).catch(({ response }) => {                  
                FormUtil.responseErrCatch(response);
                reject(response); 
            });
        })
    }
}