enum Actions {


    //-------- REFERENCES ---------//
    MATERIAL_REF_LIST = "MaterialRefList",
    MATERIAL_REF_SAVE = "MaterialRefSave",
    MATERIAL_REF_UPDATE = "MaterialRefUpdate",
    MATERIAL_REF_TRASHED_LIST = "MaterialRefTrashedList",

    LABOR_TYPES_REF_SAVE = "LaborTypesRefSave",
    LABOR_TYPES_REF_UPDATE = "LaborTypesRefUpdate",
    LABOR_TYPES_REF_TRASHED_LIST = "LaborTypesRefTrashedList",
    LABOR_TYPES_REF_LIST = "LaborTypesRefList",

    LABOR_METHODS_REF_SAVE = "LaborMethodsRefSave",
    LABOR_METHODS_REF_UPDATE = "LaborMethodsRefUpdate",
    LABOR_METHODS_REF_TRASHED_LIST = "LaborMethodsRefTrashedList",
    LABOR_METHODS_REF_LIST = "LaborMethodsRefList",

    LABOR_NAMES_REF_SAVE = "LaborNamesRefSave",
    LABOR_NAMES_REF_UPDATE = "LaborNamesRefUpdate",
    LABOR_NAMES_REF_TRASHED_LIST = "LaborNamesRefTrashedList",
    LABOR_NAMES_REF_LIST = "LaborNamesRefList",

    LABOR_HOURS_REF_SAVE = "LaborHoursRefSave",
    LABOR_HOURS_REF_UPDATE = "LaborHoursRefUpdate",
    LABOR_HOURS_REF_TRASHED_LIST = "LaborHoursRefTrashedList",
    LABOR_HOURS_REF_LIST = "LaborHoursRefList",

    UNIT_REF_SAVE = "UniRefSave",
    UNIT_REF_UPDATE = "UniRefUpdate",
    UNIT_REF_TRASHED_LIST = "UniRefTrashedList",
    UNIT_REF_LIST = "UniRefList",

    DURATIONS_REF_SAVE = "DurationsRefSave",
    DURATIONS_REF_UPDATE = "DurationsRefUpdate",
    DURATIONS_REF_TRASHED_LIST = "DurationsRefTrashedList",
    DURATIONS_REF_LIST = "DurationsRefList",

    DESIGNATIONS_REF_SAVE ="DesignationsRefSave",
    DESIGNATIONS_REF_UPDATE ="DesignationsRefUpdate",
    DESIGNATIONS_REF_TRASHED_LIST ="DesignationsRefTrashedList",
    DESIGNATIONS_REF_LIST ="DesignationsRefList",

    SCOPES_REF_SAVE = "ScopeRefSave",
    SCOPES_REF_UPDATE = "ScopeRefUpdate",
    SCOPES_REF_TRASHED_LIST = "ScopeRefTrashedList",
    SCOPES_REF_LIST = "ScopeRefList",

    SUB_SCOPES_REF_SAVE = "SubScopesRefSave",
    SUB_SCOPES_REF_UPDATE = "SubScopesRefUpdate",
    SUB_SCOPES_REF_TRASHED_LIST = "SubScopesRefTrashedList",
    SUB_SCOPES_REF_LIST = "SubScopesRefList",

    EQUIPMENTS_REF_SAVE = "EquipmentsRefSave",
    EQUIPMENTS_REF_UPDATE = "EquipmentsRefUpdate",
    EQUIPMENTS_REF_TRASHED_LIST = "EquipmentsRefTrashedList",
    EQUIPMENTS_REF_LIST = "EquipmentsRefList",
    //-------- END ---------//

    //-------- TRANSACTION ---------//
    PROJECTS_TRANS_SAVE = "ProjectsTransSave",
    PROJECTS_TRANS_UPDATE = "ProjectsTransUpdate",
    PROJECTS_TRANS_TRASHED_LIST = "ProjectsTransTrashedList",
    PROJECTS_TRANS_LIST = "ProjectsTransList",
    
    DUPA_COSTS_TRANS_SAVE = "DupaCostsTransSave",
    DUPA_COSTS_TRANS_UPDATE = "DupaCostsTransUpdate",
    DUPA_COSTS_TRANS_TRASHED_LIST = "DupaCostsTransTrashedList",
    DUPA_COSTS_TRANS_LIST = "DupaCostsTransList",

    DUPA_MATERIAL_COSTS_TRANS_SAVE = "DupaMaterialCostsTransSave",
    DUPA_MATERIAL_COSTS_TRANS_UPDATE = "DupaMaterialCostsTransUpdate",
    DUPA_MATERIAL_COSTS_TRANS_TRASHED_LIST = "DupaMaterialCostsTransTrashedList",
    DUPA_MATERIAL_COSTS_TRANS_LIST = "DupaMaterialCostsTransList",
    
    DUPA_LABOR_COSTS_TRANS_SAVE = "DupaLaborCostsTransSave",
    DUPA_LABOR_COSTS_TRANS_UPDATE = "DupaLaborCostsTransUpdate",
    DUPA_LABOR_COSTS_TRANS_TRASHED_LIST = "DupaLaborCostsTransTrashedList",
    DUPA_LABOR_COSTS_TRANS_LIST = "DupaLaborCostsTransList",

    DUPA_EQUIPMENT_COSTS_TRANS_SAVE = "DupaEquipmentCostsTransSave",
    DUPA_EQUIPMENT_COSTS_TRANS_UPDATE = "DupaEquipmentCostsTransUpdate",
    DUPA_EQUIPMENT_COSTS_TRANS_TRASHED_LIST = "DupaEquipmentCostsTransTrashedList",
    DUPA_EQUIPMENT_COSTS_TRANS_LIST = "DupaEquipmentCostsTransList",

    PROJECT_LABOR_TRANS_SAVE = "ProjectLaborTransSave",
    PROJECT_LABOR_TRANS_UPDATE = "ProjectLaborTransUpdate",
    PROJECT_LABOR_TRANS_TRASHED_LIST = "ProjectLaborTransTrashedList",
    PROJECT_LABOR_TRANS_LIST = "ProjectLaborTransList",

    
    //-------- END ---------//
}


export { Actions };