import ApiService from "@/modules/pais-template/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import { ProfileViewInterface, UserProfileInterface } from "@/modules/pais-template/views/pages/user/profile/ts/ProfileTypes";
@Module
export default class AppAdminUserModule extends VuexModule implements UserProfileInterface { 
    profileView = {
        email: '',
        first_name: '',
        image: '',
        is_me: false,
        last_name: '',
        mobile_number: '',
        unit: ''
    } as ProfileViewInterface;

    
    /**
     * Verify user if you are viewing ur own profile
     */
    get isYourProfile(): boolean {
        return this.profileDataView.is_me;
    }

    get profileDataView(): ProfileViewInterface {
        return this.profileView;
    }

    @Mutation
    [Mutations.SET_PROFILE_VIEW_DATA](data: ProfileViewInterface): void {
        this.profileView = data;
    }

    @Action
    [Actions.ADMIN_USER_INVITE]  (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/invite', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_USER_GET_SETTEAM] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.get('/api/users/account-type/' + payload.userid).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }
    @Action
    [Actions.ADMIN_USER_SET_SUPERADMIN] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.put('/users/assign-superadmin/' + payload.id, payload.data).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_USER_SET_ROLE_SAVE] (payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/roles/assign-user-to-roles', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_USER_LISTDELETE] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/users/only-trashed?' + params).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }

    @Action
    [Actions.ADMIN_USER_ACCOUNTTYPE] (payload): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/api/users/account-type', payload).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_USER_LIST] (payload) {
        return new Promise<void>((resolve, reject) => {
            const params = FormUtil.objectToString({...payload.pagination, ...payload.search});
            ApiService.get('/api/users?' + params).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }

    @Action
    [Actions.ADMIN_PROFILE] (payload: { profileId }) {
        return new Promise<void>((resolve, reject) => {
            ApiService.query('/api/users', {
                params: {
                    view: 'public',
                    id: payload.profileId
                }
            }).then(({ data }) => {
                if (data && data.data) {
                    const response = data.data;
                    this.context.commit(Mutations.SET_PROFILE_VIEW_DATA, response);
                }
                resolve(data)
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        })
    }
}