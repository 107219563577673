import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/modules/pais-template/store/enums/StoreEnums";
import ApiService from "@/modules/pais-template/core/services/ApiService";
import { FormUtil } from '@/modules/pais-template/assets/ts/_utils/_FormUtil';

@Module
export default class AppComponentLogsModule extends VuexModule {
    @Action
    [Actions.APP_COMPONENT_LOGS_SESSIONS] (payload: { pagination, search, userId }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.query('/api/users/logs/login',  { params : {
                ...payload.pagination,
                ...payload.search,
                user_id: payload.userId
            }}).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
    
    @Action
    [Actions.APP_COMPONENT_ACTIVE_SESSIONS] (payload: { pagination, search, userId }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.query('/api/users/logs/active-sessions',  { params : {
                ...payload.pagination,
                ...payload.search,
                user_id: payload.userId
            }}).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
    
    @Action
    [Actions.TERMINATE_SESSION] (payload: { accessToken, logout_all }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.query('/api/users/token-revoke',  { params : {
                access_token_id: payload.accessToken, logout_all: payload.logout_all
            }}).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => { this.context.commit(Mutations.SET_ERROR, response.data); FormUtil.responseErrCatch(response); reject(response) });
        });
    }
}