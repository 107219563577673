<template>
  <div>
      <div>sample content</div>
      <button class="btn btn-primary" @click="z">close modal</button>
      <button @click="$emit('close', close, 'this is param')">confirm</button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
    emits: ['close'],
    setup(props, {emit}) {
    //     const $vfm = inject<VueFinalModalProperty>('$vfm');

    //     const formModel = reactive({
    //         modalClass: 'app-pais-modal'
    //     });  
        
    //     $vfm?.dynamicModals;

    console.log(props);

        const z = () => {
            console.log(props);
            //console.log($vfm?.modals[0].emit.emit('d'));        
            emit('close', true);
        }

        return { z }
    }
});
</script>

<style>

</style>