import { Module, VuexModule, Mutation } from "vuex-module-decorators";

@Module
export default class CommonModuleMANTRIX extends VuexModule {
    
    get mantrixModuleBreadcrumbs() {
        return ["Module", "Mantrix-Information-System"];
        
    }
 
}